import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Contact() {
  return (
    <>
      <SEO
        title={"Conditions générales de vente"}
        keywords={[`cgv`, `conditions générales de vente`]}
      />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader
          title="Conditions générales de vente"
          header="/images/headers/4.jpg"
        />
        <Layout>
          <h3>ARTICLE 1 : INTRODUCTION</h3>
          <p>
            Les présentes Conditions Générales de Vente (CGV) s’appliquent à
            toutes les prestations proposées par Diane Le Blanc. Ainsi, chaque
            demande de prestation implique l’adhésion entière et sans réserve à
            ces présentes CGV et prévalent sur tout autre document du.de la
            client.e. Toute condition générale ou particulière opposée par
            le(la) client(e) ne peut, sauf acceptation formelle et écrite de
            Diane Le Blanc, prévaloir sur les présentes CGV.
          </p>
          <h3>ARTICLE 2 : DOCUMENTS CONTRACTUELS</h3>
          <p>
            Une fois la prestation officialisée, Diane Le Blanc envoie un
            contrat de coaching rédigé selon les textes en vigueur. Pour être
            valide, le contrat doit être dûment complété avec les noms, prénom
            et informations relatives à l’accompagnement, et être signé. Le
            contrat signé est à remettre à Diane Le Blanc, avant la première
            séance de coaching, par mail à diane.leblanc.coaching@gmail.com.
            Toute prestation de coaching entamée vaut acceptation de ces CGV.
          </p>

          <h3>ARTICLE 3 : CONDITIONS D’ANNULATION</h3>
          <p>
            1) Si un(e) client(e) ne prévient pas de son absence au moins 24
            heures à l’avance, il sera facturé à 50% comme s'il(elle) avait
            assisté à une séance. En cas d’annulation d’une séance par Diane Le
            Blanc, pour quelque motif que ce soit, elle fait en sorte que le(la)
            client(e) soit prévenu(e) au plus tôt et la séance est reportée en
            fonction des disponibilités de chacun, dans les deux semaines à
            venir.
          </p>
          <p>
            2) Le contrat est conclu pour une durée fixée aux conditions
            particulières définies entre les parties, ici à durée indéterminée.
            Le contrat pourra ainsi être résilié par l’une des parties en cas de
            manquement à ses obligations découlant du Contrat, par l’autre
            partie. Le présent article ne porte pas préjudice aux actions dont
            dispose l’une ou l’autre des parties. Tout contrat signé entre les
            parties se voit applicable dans sa totalité.
          </p>
          <h3>ARTICLE 4 : TARIFS</h3>
          <p>
            Les prix des différentes prestations sont conformes au tarif en
            vigueur au moment de la demande de prestation. Ils comprennent les
            séances, la préparation des séances, le coût du matériel utilisé et
            les échanges par mails et réseaux sociaux entre les séances.
          </p>
          <h3>ARTICLE 5 : PRÉPARATION</h3>
          <p>
            Le temps passé par Diane Le Blanc à la préparation des séances fait
            partie de l’accord mis en place dans le contrat, et est inclus dans
            le tarif en vigueur.
          </p>
          <h3>ARTICLE 6 : RÈGLEMENT</h3>
          <p>
            Une fois le contrat signé, le règlement est à effectuer par virement
            interact à l’adresse diane.leblanc.coaching@gmail.com ou bancaire
            sur le RIB de Diane Le Blanc soit avant le début d’un programme,
            soit après chaque séance.
          </p>
          <h3>ARTICLE 7 : PROPRIÉTÉ INTELLECTUELLE</h3>
          <p>
            L’ensemble des documents remis ou utilisés au cours de
            l’accompagnement constituent des œuvres originales et, à ce titre,
            sont protégées par la propriété intellectuelle et le copyright. En
            conséquence, le client s’interdit d’utiliser, copier, transmettre et
            plus généralement d’exploiter tout ou partie de ces documents, sans
            l’accord préalable et écrit de Diane Le Blanc. Ils ne peuvent en
            aucun cas être revendus ou mis à la disposition du public sous
            quelque forme que ce soit, y compris électronique.
          </p>
          <h3>ARTICLE 8 : CONFIDENTIALITÉ</h3>
          <p>
            Les parties s’engagent à garder la confidentialité quant aux
            informations et documents échangés durant les différents
            accompagnements, de quelque nature qu’ils soient (économiques,
            commerciaux, techniques, matériel pédagogique...).
          </p>
          <h3>ARTICLE 9 : RÈGLEMENT DES LITIGES</h3>
          <p>
            Le contrat est soumis au droit Canadien. Toute contestation ou
            litige portant sur l’interprétation ou l’exécution du présent
            contrat sera portée, à défaut d’un accord amiable entre les parties,
            devant les tribunaux compétents de Montréal, à qui elles attribuent
            expressément juridiction.
          </p>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
